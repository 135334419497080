// Firebase.js

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
  getFirestore,
  addDoc,
  updateDoc,
  doc,
  collection,
  onSnapshot,
  getDocs,
  arrayUnion,
  getDoc,
  query,
  where,
  setDoc,
  orderBy,
  deleteDoc,
  limit,
} from "firebase/firestore";
import { getDatabase, ref, set } from "firebase/database";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
  uploadBytesResumable,
} from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCC5MPqwI4W5XkLQ6P6bXE_Usa1AljU6Y8",
  authDomain: "foxcraft-token.firebaseapp.com",
  projectId: "foxcraft-token",
  storageBucket: "foxcraft-token.appspot.com",
  messagingSenderId: "214656308223",
  appId: "1:214656308223:web:9f2ab402d1eab5d66c8dca",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const database = getDatabase(firebaseApp);
const firestore = getFirestore(firebaseApp); // Update this line

const storage = getStorage(firebaseApp);

const authStateChanged = (callback) => {
  return auth.onAuthStateChanged(callback);
};

const initializeDatabase = () => {
  const postsRef = ref(database, "posts");
  set(postsRef, {
    postId1: {
      title: "Sample Title 1",
      description: "Sample Description 1",
      image: "sample-image-1.jpg",
      link: "https://example.com/1",
    },
    postId2: {
      title: "Sample Title 2",
      description: "Sample Description 2",
      image: "sample-image-2.jpg",
      link: "https://example.com/2",
    },
    // Add more posts as needed
  });
};

export {
  auth,
  database,
  storage,
  firestore,
  deleteDoc,
  authStateChanged,
  firebaseApp as default,
  initializeDatabase,
  orderBy,
  collection,
  getDocs,
  setDoc,
  limit,
  arrayUnion,
  addDoc,
  onSnapshot,
  uploadBytes,
  storageRef,
  ref,
  getDownloadURL,
  doc,
  updateDoc,
  getDoc,
  uploadBytesResumable,
  where,
  query,
};
