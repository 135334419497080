import React, { useState, useEffect } from "react";
import {
  collection,
  addDoc,
  getDoc,
  doc,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";
import { firestore, auth } from "../utils/Firebase";
import { onAuthStateChanged } from "firebase/auth";

const InviteComponent = ({ communityId }) => {
  const [user, setUser] = useState(null);
  const [inviteLink, setInviteLink] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (authUser) => {
      console.log("Community ID:", communityId);
      if (authUser) {
        setUser(authUser);
        // Load existing invite link if available
        await loadInviteLink(authUser.uid);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, [communityId]);

  const loadInviteLink = async (userId) => {
    try {
      const communityDocRef = doc(firestore, "communities", communityId);
      const communityDocSnapshot = await getDoc(communityDocRef);
      const communityData = communityDocSnapshot.data();
      if (communityData && communityData.inviteLink) {
        setInviteLink(communityData.inviteLink);
      }
    } catch (error) {
      console.error("Error loading invite link:", error);
    }
  };

  const generateInviteLink = async () => {
    if (user && communityId) {
      try {
        console.log("Generating invite link for communityId:", communityId); // Log the communityId

        // Check if an invite link already exists
        if (inviteLink) {
          alert(`Invite Link: ${inviteLink}`);
          return;
        }

        // Generate a unique token for the invite
        const inviteToken = generateUniqueToken();

        // Store the token in the community's document in Firestore
        const communityDocRef = doc(firestore, "communities", communityId);
        await updateDoc(communityDocRef, {
          inviteTokens: arrayUnion(inviteToken),
          inviteLink: `http://localhost:3000/#/community/${communityId}/invite/${inviteToken}`,
        });

        const newInviteLink = `http://localhost:3000/#/community/${communityId}/invite/${inviteToken}`;
        setInviteLink(newInviteLink);
        console.log("Generated invite link:", newInviteLink); // Log the generated link

        alert(`Invite Link: ${newInviteLink}`);
      } catch (error) {
        console.error("Error generating invite link:", error);
      }
    } else {
      console.error("Invalid user or communityId:", user, communityId);
    }
  };

  const copyToClipboard = () => {
    if (inviteLink) {
      navigator.clipboard.writeText(inviteLink);
      alert("Invite link copied to clipboard!");
    }
  };

  const generateUniqueToken = () => {
    return Math.random().toString(36).substring(2, 10);
  };

  return (
    <div>
      <h2>Invite Friends</h2>
      {user && (
        <>
          <button onClick={generateInviteLink}>Generate Invite Link</button>
          {inviteLink && (
            <div>
              <p>Invite Link: {inviteLink}</p>
              <button onClick={copyToClipboard}>Copy to Clipboard</button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default InviteComponent;
