import React from "react";
import {
  FaDiscord,
  FaEnvelope,
  FaGithub,
  FaTelegram,
  FaTwitter,
} from "react-icons/fa";

const Footer = () => {
  const handleEmailClick = () => {
    window.location.href = "mailto:support@k9kash.network";
  };

  return (
    <div className="footer">
      <div className="credit">Follow us</div>
      <div className="col-md-3">
        <div id="footer-socials">
          <div className="socials inline-inside socials-colored">
            <a
              href="http://x.com/K9kash_official"
              target="_blank"
              title="Twitter"
              className="socials-item"
            >
              <FaTwitter />
            </a>
            <a
              href="http://t.me/K9Kash"
              target="_blank"
              title="Telegram"
              className="socials-item"
            >
              <FaTelegram />
            </a>
            <a
              href="https://discord.com/invite/YNS6YNNAaU"
              target="_blank"
              title="discord"
              className="socials-item"
            >
              <FaDiscord />
            </a>

            <a
              href="mailto:support@k9kash.network"
              onClick={handleEmailClick}
              title="Email"
              className="socials-item"
            >
              <FaEnvelope />
            </a>

            <a
              href="http://www.github.com/k9Kash"
              target="_blank"
              title="github"
              className="socials-item"
            >
              <FaGithub />
            </a>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  );
};

export default Footer;
