import React from "react";
import ButtonWithScrollHide from "../component/ScrollButton";
import smart from "../img/smartcontract.jpg";
import protocol from "../img/protocol.jpg";
import security from "../img/audit.jpg";
import connect from "../img/interconnected.jpg";
import fun from "../img/fun.jpg";

const FoxCivilizationComponent = () => {
  return (
    <div className="ecosystem">
      <img height="500px" src={protocol} />
      <h2>Introducing the K9kash Protocol</h2>

      <p>
        K9kash Protocol is a robust framework designed to empower
        decentralization and drive innovation within the Syscoin blockchain.
        Built upon principles of transparency, security, and community
        engagement, the K9kash Protocol serves as the backbone of our
        revolutionary platform, enabling a wide range of functionalities and
        opportunities for users worldwide.
      </p>
      <br />
      <br />
      <h2>Key Components of the K9kash Protocol</h2>
      <br />
      <div className="ecosystem-grid">
        <div className="ecosystem-div">
          <img src={connect} />
          <h3>Interoperability with Syscoin</h3>
          <p>
            {" "}
            As a protocol native to the Syscoin blockchain, K9kash seamlessly
            integrates with existing Syscoin infrastructure, leveraging its
            speed, security, and scalability to facilitate fast and
            cost-effective transactions. This interoperability enables seamless
            interaction between K9kash and other Syscoin-based projects,
            fostering a cohesive and interconnected ecosystem.
          </p>
        </div>
        <div className="ecosystem-div">
          <img src={smart} />
          <h3>Smart Contract Functionality</h3>
          <p>
            {" "}
            Leveraging the power of smart contracts, the K9kash Protocol enables
            the creation and execution of programmable agreements, opening up a
            world of possibilities for decentralized applications (dApps),
            financial instruments, and tokenized assets. Smart contract
            functionality empowers users to automate complex processes, execute
            trustless transactions, and unlock new avenues for innovation.
          </p>
        </div>
        <div className="ecosystem-div">
          <img src={fun} />
          <h3>Tokenomics and Incentive Mechanisms</h3>{" "}
          <p>
            The K9kash Protocol incorporates robust tokenomics and incentive
            mechanisms designed to align the interests of participants and
            promote network growth. Through features such as staking, liquidity
            mining, crowd funding, token burn and governance rewards, users are
            incentivized to actively participate in the ecosystem, contributing
            to its vitality and sustainability over time.
          </p>
        </div>
        <div className="ecosystem-div">
          <img src={security} />
          <h3>Security and Auditability</h3>
          <p>
            Security is paramount within the K9kash Protocol, with rigorous
            auditing processes and best practices implemented to safeguard user
            assets and data. Smart contracts undergo comprehensive security
            audits by reputable third-party firms to mitigate risks and ensure
            the integrity of the platform. Additionally, transparent reporting
            and auditing mechanisms provide users with visibility into the
            platform's operations, fostering trust and confidence in the
            ecosystem.
          </p>
        </div>
      </div>
    </div>
  );
};

export default FoxCivilizationComponent;
