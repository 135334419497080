import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import { HashRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import NFT from "./pages/NFT";
import Metaverse from "./pages/Metaverse";
import Navbarmenu from "./component/Navbar";
import FoxCivilizationComponent from "./pages/FoxCivilization";
import { auth, authStateChanged } from "./utils/Firebase";
import Portal from "./pages/Portal";
import Footer from "./component/Footer";
import CreateBlog from "./blog/CreateBlog";
import BlogDetails from "./blog/BlogDetails";
import AllBlogs from "./blog/BlogPost";
import SignInComponent from "./auth/SignIn";
import AdminRoute from "./AdminRoutes";
import Dashboard from "./dashboard/Dashboard";
import CreateEvent from "./dashboard/CreateEvent";
import AdminPanel from "./dashboard/AdminTask";
import Register from "./auth/Register";
import SignUpComponent from "./auth/SignUp";
import ForgotPasswordComponent from "./auth/ResetPassword";
import InvitationHandler from "./dashboard/HandleInvitation";
import CommunityCenter from "./dashboard/CommunityCenter";
import ScrollToTop from "./component/ScrollToTop";

const milestones = [
  {
    title: "Phase 1",
    description: "Airdrop",
    date: "2024",
  },
  {
    title: "Phase 2",
    description: "KASH Pre-market",
    date: "2024",
  },
  {
    title: "Phase 3",
    description: "Burn it Up",
    date: "2024",
  },
  {
    title: "Phase 4",
    description: "Kash Dapp Testnet",
    date: "2024",
  },
  {
    title: "Phase 5",
    description: "KASH Dapp Mainnet",
    date: "2024",
  },
  {
    title: "Phase 6",
    description: "Kash DAO",
    date: "2024",
  },

  {
    title: "Phase 7",
    description: "Kash Lend",
    date: "2024",
  },
  {
    title: "Phase 8",
    description: "KashPay",
    date: "2025",
  },

  {
    title: "Phase 9",
    description: "Up Up and Away",
    date: "2025",
  },

  // Add more milestones as needed
];

function App() {
  return (
    <HashRouter>
      <div>
        <ScrollToTop />
        <div className="main-container">
          <Navbarmenu />
          <div>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/home" element={<Home />} />
              <Route
                path="/about"
                element={<About milestones={milestones} />}
              />
              <Route
                path="/KASH-protocol"
                element={<FoxCivilizationComponent />}
              />
              <Route path="/Airdrop&Listing" element={<NFT />} />

              <Route path="/Launchpad" element={<Metaverse />} />

              <Route path="/home" element={<Home />} />
              {/**   <Route
                path="/create-blog"
                element={<AdminRoute element={<CreateBlog />} user={user} />}
              />
              <Route
                path="/create-event"
                element={<AdminRoute element={<CreateEvent />} user={user} />}
              />
              <Route
                path="/task"
                element={<AdminRoute element={<AdminPanel />} user={user} />}
              />

              <Route path="/blog/:latestBlogs.id" element={<BlogDetails />} />
              <Route path="/blog/:id" element={<BlogDetails />} />
              <Route
                path="/Forget-password"
                element={<ForgotPasswordComponent />}
              />

              <Route path="/blog" element={<AllBlogs />} />
              <Route path="/sign-in" element={<SignInComponent />} />

              <Route
                path="/invite/:inviteToken"
                element={<InvitationHandler />}
              /> 
               {isAuthenticated && user && (
                <>
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/register" element={<Register />} />
                  <Route
                    path="/community/:communityId"
                    element={
                      <AdminRoute element={<CommunityCenter />} user={user} />
                    }
                  />
                </>
              )}*/}

              <Route path="*" element={<Navigate to="/" Home />} />
            </Routes>
          </div>
        </div>
      </div>
      <Footer />
    </HashRouter>
  );
}

export default App;
