import React from "react";
import civilization from "../img/img2.jpg";
import ButtonWithScrollHide from "../component/ScrollButton";
import launch from "../img/launch2.jpg";

const Metaverse = () => {
  return (
    <div className="nft-bg main-container">
      <div className="ecosystem">
        <img height="500px" src={launch} />
        <br />
        <h2>
          The K9kash Launchpad <br />
          Igniting Growth and Innovation on the Syscoin Blockchain
        </h2>{" "}
        <p>
          The K9kash Launchpad is designed to accelerate growth and foster
          innovation within the vibrant landscape of the Syscoin blockchain.
          Anchored by a three-stage framework, our Launchpad serves as a beacon
          of opportunity for aspiring projects, guiding them through a journey
          of validation, community support, and market readiness.
        </p>
        <hr />
        <h2>Phase 1: Incubation</h2>
        <p>
          The journey commences with the Incubation phase, where projects
          undergo a rigorous vetting process to ensure alignment with the ethos
          of the Syscoin ecosystem. Here, projects must demonstrate not only
          technical proficiency but also a deep understanding of the unique
          opportunities and challenges presented by Syscoin's decentralized
          infrastructure. During the Incubation phase, projects undergo
        </p>
        <div className="launch-grid">
          <div className="launch-div">
            <h3>Team KYC</h3>
            <p>
              Fostering transparency and accountability by verifying the
              identities of project leaders, instilling confidence within the
              community and potential investors.
            </p>{" "}
          </div>
          <div className="launch-div">
            <h3>Smart Contract Audit</h3>
            <p>
              Upholding the highest standards of security and reliability
              through comprehensive audits of project smart contracts,
              safeguarding against vulnerabilities and ensuring trust within the
              ecosystem.
            </p>
          </div>
          <div className="launch-div">
            <h3>Whitepaper Reviews</h3>
            <p>
              Articulating a clear vision and roadmap for the project,
              showcasing its potential to contribute meaningfully to the growth
              and evolution of the Syscoin blockchain.
            </p>{" "}
          </div>
          <div className="launch-div">
            <h3>Ecosystem Development</h3>
            <p>
              Demonstrating a commitment to collaborative innovation by
              proposing solutions that enhance the functionality and
              accessibility of the Syscoin ecosystem, driving sustained growth
              and adoption. Only projects that successfully navigate the
              Incubation phase earn the privilege of advancing to the subsequent
              stages of the Launchpad journey.{" "}
            </p>{" "}
          </div>
        </div>
        <hr />
        <h2>Phase 2: Crowdfunding</h2>
        <p>
          With the foundation laid during the Incubation phase, projects
          progress to the Crowdfunding stage, where they harness the power of
          the Syscoin community to secure the resources needed to fuel their
          growth and development. Here, projects engage with a diverse array of
          stakeholders, rallying support and building momentum for their vision.
          During Crowdfunding, projects benefit from:
        </p>{" "}
        <div className="launch-grid">
          <div className="launch-div">
            <h3>Community Support</h3>{" "}
            <p>
              Harnessing the collective wisdom and enthusiasm of the Syscoin
              community to amplify their message, broaden their reach, and
              attract early backers and supporters.{" "}
            </p>
          </div>
          <div className="launch-div">
            <h3>Token Distribution</h3>{" "}
            <p>
              Leveraging the Syscoin blockchain's robust infrastructure to
              facilitate fair and transparent token distribution, ensuring
              equitable access to project resources and fostering a sense of
              ownership and belonging within the community.
            </p>
          </div>
          <div className="launch-div">
            <h3>Strategic Partnerships</h3>{" "}
            <p>
              {" "}
              Cultivating strategic alliances with key stakeholders within the
              Syscoin ecosystem, including developers, entrepreneurs, and
              industry leaders, to unlock synergies, accelerate growth, and
              maximize impact.
            </p>
          </div>
        </div>
        <hr />
        <h2>Phase 3: The Launch</h2>
        <p>
          The culmination of the Launchpad journey is the Launching stage, where
          projects officially debut to the world, equipped with the support and
          validation of the Syscoin community. Here, projects leverage the
          Syscoin blockchain's unparalleled speed, security, and scalability to
          realize their vision and unlock new opportunities for innovation and
          prosperity. During the Launching phase, projects benefit from:
        </p>{" "}
        <div className="launch-grid">
          <div className="launch-div">
            <h3>Market Access</h3>
            <p>
              Accessing a global network of exchanges and trading platforms
              integrated with the Syscoin blockchain, facilitating liquidity and
              enabling seamless token trading and distribution to a diverse
              audience of investors and enthusiasts.
            </p>{" "}
          </div>
          <div className="launch-div">
            <h3>Community Engagement</h3>
            <p>
              Nurturing ongoing engagement and collaboration within the Syscoin
              community, fostering a culture of creativity, resilience, and
              continuous improvement that propels projects to new heights of
              success.
            </p>
          </div>

          <div className="launch-div">
            <h3>Continuous Support</h3>
            <p>
              Receiving ongoing guidance and support from the K9kash team and
              the broader Syscoin community, empowering projects to navigate
              challenges, seize opportunities, and realize their full potential
              in a rapidly evolving digital landscape. In essence, the K9kash
              Launchpad represents more than just a platform for project
              incubation and crowdfunding – it's a catalyst for growth, a
              catalyst for innovation, and a testament to the transformative
              power of the Syscoin blockchain.{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Metaverse;
