import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import portal from "../img/craft1.png";
import craft from "../img/craft2.png";
import token from "../img/collection (1).png";
import pay from "../img/pay.jpg";
import launch from "../img/launh.jpg";
import stake from "../img/stake.jpg";
import vote from "../img/vote.jpg";
import {
  firestore,
  collection,
  getDocs,
  authStateChanged,
  query,
  orderBy,
  limit,
} from "../utils/Firebase";

import Roadmap from "./Roadmap";
import BannerSlider from "../utils/BannerSlider";
import ButtonWithScrollHide from "../component/ScrollButton";

const truncateContent = (content, maxLength) => {
  if (content.length <= maxLength) {
    return content;
  } else {
    return content.slice(0, maxLength) + "...";
  }
};

const About = ({ milestones }) => {
  const navigate = useNavigate();
  const [latestBlogs, setLatestBlogs] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const unsubscribe = authStateChanged((user) => {
      setIsAuthenticated(!!user); // Set isAuthenticated to true if user exists, false otherwise

      setIsAdmin(user && user.email === "foxcraft@gmail.com");
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchLatestBlogs = async () => {
      try {
        const querySnapshot = await getDocs(
          query(
            collection(firestore, "blogs"),
            orderBy("createdAt", "desc"),
            limit(2)
          )
        );

        const latestBlogsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setLatestBlogs(latestBlogsData);
      } catch (error) {
        console.error("Error fetching latest blog posts: ", error);
      }
    };

    fetchLatestBlogs();
  }, []);

  const handleFrameClick = (destination) => {
    navigate(destination);
  };

  const handleEnter = () => {
    if (isAuthenticated) {
      navigate("/dashboard");
    } else {
      navigate("/sign-up");
    }
  };

  return (
    <div className="home">
      <div className="header">
        <h2>Memecoin On Syscoin</h2>
        <p>
          K9kash coin pride itself as the first memecoin built on Rollux L2 to
          empower decentralized community build on the scalability and security
          of syscoin ecosystem
        </p>
      </div>
      <div className="ecosystem">
        <h2>What are we building</h2>
        <h2>A Decentralized Solution for the Syscoin community</h2>
        <div className="ecosystem-grid">
          <div className="ecosystem-div">
            <img src={launch} />
            <h2>Kash Dapp</h2>
            <p>
              Kash DApp is a decentralized application for crowd funding web3
              ideas and to encourage developers to build on the syscoin
              blockchain
            </p>
          </div>
          <div className="ecosystem-div">
            <img src={pay} />
            <h2>KashPay</h2>
            <p>
              Kash Pay, a decentralized payment gateway built ontop of syscoin
              scalability and security protocol that integrate two native
              currency for payment. users will be able to perform close and
              cross border payment for goods and service with KASH and SYSCOIN
              with a transfer block confirmation
            </p>
          </div>
          <div className="ecosystem-div">
            <img src={vote} />
            <h2>KashDAO</h2>
            <p>
              PAY is a utility token within the KASH ecosystem that is use for
              voting on chain proposals. it has a relatively small in supply. To
              get PAY, holders must stake KASH in the DAO app. Staked KASH can
              be unstaked after 30 days
            </p>
          </div>
          <div className="ecosystem-div">
            <img src={stake} />
            <h2>KashLend</h2>
            <p>
              K9Kash is rolling out KashLend, a defi layer that fosters SYS,KASH
              staking, lending and borrowing incentives to developers and
              investors seeking to put their SYS to use. Other Syscoin based
              token will be integrated as our ecosystem grow
            </p>
          </div>
        </div>
        <div className="economics">
          <div>
            <h2>TOKENOMICS</h2>
            <p className="tokenomics">
              KASH boast of a 100 trillion $KASH coin as its max supply. 51
              trillion (51%) has been burnt already leaving a total supply of 49
              trillion tokens. More tokens burns will occur somewhere in the
              future through team and community effort and utility integrations
            </p>
          </div>
        </div>

        <div>
          <Roadmap milestones={milestones} />
          <p></p>
        </div>
      </div>
    </div>
  );
};

export default About;
