import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import portal from "../img/craft1.png";
import craft from "../img/craft2.png";
import logo from "../img/hash.png";
import {
  firestore,
  collection,
  getDocs,
  authStateChanged,
  query,
  orderBy,
  limit,
} from "../utils/Firebase";

import Roadmap from "./Roadmap";
import BannerSlider from "../utils/BannerSlider";
import ButtonWithScrollHide from "../component/ScrollButton";

const truncateContent = (content, maxLength) => {
  if (content.length <= maxLength) {
    return content;
  } else {
    return content.slice(0, maxLength) + "...";
  }
};

const Home = () => {
  const navigate = useNavigate();
  const [latestBlogs, setLatestBlogs] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const unsubscribe = authStateChanged((user) => {
      setIsAuthenticated(!!user); // Set isAuthenticated to true if user exists, false otherwise

      setIsAdmin(user && user.email === "foxcraft@gmail.com");
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchLatestBlogs = async () => {
      try {
        const querySnapshot = await getDocs(
          query(
            collection(firestore, "blogs"),
            orderBy("createdAt", "desc"),
            limit(2)
          )
        );

        const latestBlogsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setLatestBlogs(latestBlogsData);
      } catch (error) {
        console.error("Error fetching latest blog posts: ", error);
      }
    };

    fetchLatestBlogs();
  }, []);

  const handleFrameClick = (destination) => {
    navigate(destination);
  };

  const handleEnter = () => {
    if (isAuthenticated) {
      navigate("/dashboard");
    } else {
      navigate("/sign-up");
    }
  };

  return (
    <div className="home">
      <div className="home-intro">
        <h1>Welcome to K9kash</h1>
      </div>
    </div>
  );
};

export default Home;
