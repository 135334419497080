import React from "react";
import nftcollection from "../img/collection (1).jpg";
import ButtonWithScrollHide from "../component/ScrollButton";
import airdrop from "../img/airdrop.jpg";

const NFT = () => {
  return (
    <div className="ecosystem">
      <img height="500px" src={airdrop} />
      <br />
      <h2> Spreading the K9kash Revolution Far and Wide</h2>
      <p>
        At K9kash, inclusivity is at the core of everything we do. That's why
        we've allocated a generous portion of our token supply a whopping 1
        trillion KASH for an exciting airdrop campaign aimed at reaching out to
        the broader crypto community. But that's not all we're taking engagement
        to the next level by rewarding our supporters for their active
        participation in our social media channels.
      </p>{" "}
      <h2>Airdrop</h2>
      <p>
        We're thrilled to announce that 1 trillion KASH tokens have been
        earmarked for our eagerly anticipated airdrop campaign. This isn't just
        about distributing tokens it's about building a vibrant, engaged
        community united by a shared passion for decentralization and
        innovation. Participating in the K9kash airdrop is easy simply follow
        our social media channels and stay tuned for announcements on how to
        claim your free KASH tokens. But here's the best part: the more you
        engage with our tweets, posts, and updates, the more KASH you'll earn.
        It's our way of saying thank you for your support and dedication to the
        K9kash revolution.
      </p>{" "}
      <h2>Listings</h2>
      <p>
        We understand that access to liquidity is essential for the success of
        any project. That's why we're committed to ensuring that K9kash is
        listed on reputable exchanges that share our values of transparency,
        security, and community-centricity. While the exact listing date is
        still under wraps, rest assured that we're working tirelessly behind the
        scenes to finalize arrangements with our exchange partners. We'll be
        communicating the listing date directly to the crypto community through
        our official channels, so be sure to stay tuned for updates. In summary,
        the K9kash airdrop and listings represent more than just milestones
        they're a testament to our unwavering commitment to democratizing access
        to decentralized finance and digital creativity.
      </p>
    </div>
  );
};

export default NFT;
